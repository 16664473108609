<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Operarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Operarios</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <!-- SE LLAMA A LA VISTA PARA UNA TABLA DINAMICA -->
                      <button
                        type="button"
                        class="btn btn-secondary"
                        @click="pivotTable()"
                        v-if="$store.getters.can('admin.operarios.pivotTable')"
                      >
                        <i class="fas fa-scroll"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_operario_export"
                        v-if="$store.getters.can('admin.operarios.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="$store.getters.can('admin.operarios.create')"
                      >
                        <i class="fas fa-user-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        Tipo Documento
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_documento"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_documento in listasForms.tipo_documentos"
                            :key="tipo_documento.numeracion"
                            :value="tipo_documento.numeracion"
                          >
                            {{ tipo_documento.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Numero Documento
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.numero_documento"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>Tipo de cargo</th>
                      <th>Telefono</th>
                      <th>Localidad</th>
                      <th>
                        Líneas Negocio
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="linea_negocio"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.lineas_negocio"
                          :filterable="true"
                          multiple
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Empresa
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="listasForms.selct_empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="empresas"
                          :filterable="false"
                          @search="buscarEmpresas"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>Estado Documental</th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in operarios.data" :key="item.id">
                      <td>
                        <a
                          :href="uri_docs + item.link_fotografia"
                          target="_blank"
                        >
                          <img
                            :src="uri_docs + item.link_fotografia"
                            alt="Logo"
                            width="75"
                            class="rounded mx-auto d-block"
                          />
                        </a>
                      </td>
                      <td>{{ item.tipoDoc }}</td>
                      <td>{{ item.numero_documento }}</td>
                      <td>{{ item.nombres + " " + item.apellidos }}</td>
                      <td>
                        {{
                          item.tipo_cargo == 1
                            ? "Operario"
                            : item.tipo_cargo == 2
                            ? "Aparejador"
                            : ""
                        }}
                      </td>
                      <td>{{ item.celular_principal }}</td>
                      <td>{{ item.loc }}</td>
                      <td class="text-center">
                        <div
                          v-for="lineas in item.lineas_negocio"
                          :key="lineas.id"
                        >
                          <span class="badge bg-navy">
                            {{ lineas.nombre }}
                          </span>
                        </div>
                      </td>
                      <td>
                        {{ item.empresa.razon_social }}
                      </td>
                      <td>
                        <div class="row">
                          <div class="col-md-6">
                            <span
                              v-if="item.documentos_vencidos.length > 0"
                              class="badge badge-danger"
                            >
                              Documentos vencidos ({{
                                item.documentos_vencidos.length
                              }})
                            </span>
                            <span
                              v-if="item.paquetes.length <= 0"
                              class="badge badge-danger"
                            >
                              Sin paquete Documental
                            </span>

                            <span
                              v-if="item.documentos_pendientes.length > 0"
                              class="badge badge-warning"
                            >
                              Documentos pendientes ({{
                                item.documentos_pendientes.length
                              }})
                            </span>

                            <span
                              v-if="
                                item.documentos_no_verificados.length > 0
                              "
                              class="badge badge-info"
                            >
                              Documentos no verificados ({{
                                item.documentos_no_verificados.length
                              }})
                            </span>

                            <span
                              v-if="
                                item.documentos_vencidos.length === 0 &&
                                  item.documentos_pendientes.length === 0 &&
                                  item.documentos_no_verificados.length ===
                                    0 && item.paquetes.length > 0
                              "
                              class="badge badge-success"
                            >
                              Verificado
                            </span>
                          </div>
                        </div>
                      </td>
                      <td style="width: 85px" align="right">
                        <div class="row">
                          <div class="col-md-6">
                            <span
                              class="badge"
                              data-toggle="modal"
                              data-target="#modal-form-estado"
                              style="cursor: pointer"
                              v-if="
                                $store.getters.can(
                                  'admin.operarios.editStatusHSE'
                                )
                              "
                              v-bind:class="[
                                item.estado == 1
                                  ? 'badge-success'
                                  : 'badge-danger',
                              ]"
                              @click="llenarModal(item)"
                            >
                              {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                            </span>
                            <span
                              class="badge"
                              v-else
                              v-bind:class="[
                                item.estado == 1
                                  ? 'badge-success'
                                  : 'badge-danger',
                              ]"
                            >
                              {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                            </span>
                          </div>
                          <div class="col-md-6">
                            <i
                              class="fas fa-eye"
                              data-toggle="modal"
                              data-target="#modal-historial-estado"
                              style="cursor: pointer"
                              v-if="$store.getters.can('admin.operarios.show')"
                              @click="
                                llenarModalHistorial('App\\Operario', item.id)
                              "
                            ></i>
                          </div>
                        </div>
                      </td>

                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('admin.operarios.show')"
                            @click="edit(item, 1)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                          <div v-if="empresa.length > 0">
                            <div v-if="item.tipo_propiedad == 1">
                              <div v-if="item.empresa.length > 0">
                                <div>
                                  <button
                                    type="button"
                                    class="btn btn-sm bg-navy"
                                    v-if="
                                      $store.getters.can(
                                        'admin.operarios.edit'
                                      ) && empresa[0].id == item.empresa.id
                                    "
                                    @click="edit(item, 2)"
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <button
                                type="button"
                                class="btn btn-sm bg-navy"
                                v-if="
                                  $store.getters.can('admin.operarios.edit')
                                "
                                @click="edit(item, 2)"
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                            </div>
                          </div>
                          <div v-else>
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="$store.getters.can('admin.operarios.edit')"
                              @click="edit(item, 2)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="operarios.total">
                  <p>
                    Mostrando del <b>{{ operarios.from }}</b> al
                    <b>{{ operarios.to }}</b> de un total:
                    <b>{{ operarios.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="operarios"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <OperarioEstado ref="OperarioEstado" />
        <OperarioExport ref="OperarioExport" />
        <HistorialEstado ref="HistorialEstado" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import OperarioEstado from "./OperarioEstado";
import OperarioExport from "./OperarioExport";
import HistorialEstado from "../historialEstado/HistorialEstado";
import vSelect from "vue-select";

export default {
  name: "Operarios",
  components: {
    pagination,
    vSelect,
    OperarioEstado,
    HistorialEstado,
    OperarioExport,
  },
  data() {
    return {
      linea_negocio: [],
      empresas: [],
      empresa: this.$store.getters.getUser.empresas,
      tipo_usuario_cambia_estado: null,
      cargando: false,
      filtros: {
        tipo_documento: null,
        numero_documento: null,
        empresa_id: null,
        estado: null,
        tipo_cargo: null,
      },
      operarios: {},
      listasForms: {
        tipo_documentos: [],
        estados: [],
        lineas_negocio: [],
        selct_empresa: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    getIndex(page = 1) {
      this.filtros.empresa_id = null;
      this.cargando = true;
      // Se busca los permisos que tuiene el usuario  para editar los estados del operador
      if (this.$store.getters.can("admin.operarios.editStatusInspector")) {
        this.tipo_usuario_cambia_estado = `Inspector`;
      }
      if (this.$store.getters.can("admin.operarios.editStatusHSE")) {
        this.tipo_usuario_cambia_estado = `HSE`;
      }
      this.filtros.lineas_negocio = [];
      this.linea_negocio.forEach((linea) => {
        this.filtros.lineas_negocio.push(linea.id);
      });
      if (this.listasForms.selct_empresa) {
        this.filtros.empresa_id = this.listasForms.selct_empresa.id;
      }
      axios
        .get("/api/admin/operarios?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.operarios = response.data;
          this.cargando = false;
        });
    },

    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getTiposIdentificacion() {
      axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipo_documentos = response.data;
      });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroy(idOperario) {
      this.$swal({
        title: "Esta seguro de eliminar este Operario?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, ElimInar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/admin/operarios/" + idOperario).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el operario exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    llenarModal(operario) {
      if (
        this.$store.getters.can("admin.operarios.editStatusHSE") ||
        this.$store.getters.can("admin.operarios.editStatusInspector")
      ) {
        this.$refs.OperarioEstado.form_estado = {};
        this.$refs.OperarioEstado.llenar_modal(
          operario,
          this.tipo_usuario_cambia_estado
        );
      }
    },

    llenarModalHistorial(entidad, entidad_id) {
      if (this.$store.getters.can("admin.operarios.show")) {
        this.$refs.HistorialEstado.llenar_modal(entidad, entidad_id);
      }
    },

    create() {
      return this.$router.push({
        name: "/Admin/OperarioForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(operario, opcion) {
      return this.$router.push({
        name: "/Admin/OperarioForm",
        params: {
          accion: "Editar",
          data_edit: operario,
          id: operario.id,
          opcion: opcion,
        },
      });
    },

    pivotTable() {
      return this.$router.push({
        name: "/Admin/OperarioPivotTable",
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTiposIdentificacion();
    this.getMsg();
    this.getLineasNegocio();
  },
};
</script>
